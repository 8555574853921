<template>
  <div
    class="latest-area section-padding"
    v-show="showNews"
    style="padding: 50px 0"
  >
    <div class="container">
      <div class="row ppppp">
        <div class="col-md-12">
          <div class="section-title-wrapper">
            <div class="section-title">
              <h3 style="margin-bottom: 24px">{{ NewsT }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row down_nt">
        <div
          v-for="news in News"
          :key="news.postId"
          class="col-lg-4 col-md-6 col-12"
        >
          <div class="single-item">
            <div class="single-item-image overlay-effect">
              <a
                ><img
                  loading="lazy"
                  height="400"
                  width="400"
                  alt=""
                  v-bind:src="
                    'https://api2.yuniv.net:444/images/post/' + news.postImage
                  "
              /></a>
            </div>
            <div class="single-item-text">
              <h4>
                <a>{{ news.postTitle }}</a>
              </h4>
              <p>{{ news.postSubTitle }}</p>
            </div>
            <div class="button-bottom">
              <router-link
                href="#"
                aria-label="title"
                class="button-default"
                :to="{
                  name: 'NewsDetails-with-id-And-Type-And-BrnId',
                  params: {
                    type: 'Univ.Home.Sec.News',
                    id: news.postID,
                    BrnId: 'U',
                  },
                }"
              >
                {{ Detials }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="padding:30px 0 0">

      <div class="col-md-12 col-sm-12" style="text-align: left">
          <router-link
            href="#"
            aria-label="title"
            :to="{
              name: 'News-With-Type-And_BrnId',
              params: {
                type: 'Univ.Home.Sec.News',
                BrnId: 'U',
              },
            }"
            class="button-default button-large"
          >
            {{ BtnMore }} <i class="fa fa-chevron-left"></i
          ></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ArJson from "../../../public/i18n/Ar.json";
import EnJson from "../../../public/i18n/En.json";

export default {
  data() {
    return {
      showNews: true,
      News: [],
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
      NewsT: "",
      AboutUs: "",
      BtnMore: "",
      Detials: "",
    };
  },
  mounted() {
    var self_ = this;
    if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yuniv.net:444/languages/getLangIsMain",
      }).then(function (response) {
        localStorage.setItem("lang", response.data[0]["LangId"]);
        self_.getApis();
      });
    } else self_.getApis();
  },
  methods: {
    getApis() {
      window.scroll(0, 0);
      var self = this;

      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
      } else {
        self.translate = self.EnTranslat;
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "./style-ltr.css");
        document.head.appendChild(link1);
      }
      self.NewsT = self.translate[0]["Home"]["News"];
      self.BtnMore = self.translate[0]["Home"]["BtnDetials"];
      self.Detials = self.translate[0]["Home"]["Detials"];

      var bodyFormData = new FormData();
      bodyFormData.append("check", "getPost");
      bodyFormData.append("PostId", "");
      bodyFormData.append("Lang", localStorage.getItem("lang"));
      bodyFormData.append("FbrnId", "");
      bodyFormData.append("Type", "Univ.Home.Sec.News");
      bodyFormData.append("ParentId", "NTROOT0");
      bodyFormData.append("PostName", "");
      bodyFormData.append("Pno", "3");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData,
      }).then(function (response) {
        self.News = response.data;
        var arr = response.data;

        if (arr.length != 0) {
          self.showNews = true;
        } else {
          self.showNews = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.ppppp {
  margin-bottom: 70px;
}
</style>
